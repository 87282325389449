<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="col-form-label text-label">
                    Đối tác
                    <span class="text-default"></span>
                  </label>
                  <select
                    class="form-control"
                    id="slc-partner"
                    v-model="partner"
                    name="partner"
                  >
                    <option value="VCB">VCB</option>
                    <option value="ONEPAY">ONEPAY</option>
                    <option value="VPB">VPB</option>
                    <option value="VPB_CYBS">VPB_CYBS</option>
                    <option value="STB_CYBS">STB_CYBS</option>
                    <option value="VNPAY_PORTONE">VNPAY_PORTONE</option>
                    <option value="ZALOPAY_WALLET">ZALOPAY_WALLET</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="custom-fre-upload text-center p-4">
                  <label for=""
                    >Tải lên file: <span class="text-danger">*</span>
                    <b-form-file
                      v-model="file"
                      :state="Boolean(file)"
                      placeholder="Tải lên file...(xls, xlsx)"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </label>
                  <p class="">
                    <em
                      >Cập nhật file excel theo format từ
                      <a
                        class="font-bold font-weight-bold fz-15"
                        id="link-download-template"
                        target="_blank"
                        :href="getLinkTemplate()"
                      >
                        file_mẫu
                      </a>
                      trước khi tiến hành import
                    </em>
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  id="btn_import"
                  :disabled="!file"
                  class="ladda-button btn btn-primary"
                  data-style="expand-right"
                  @click="upload()"
                >
                  <span class="ladda-label">Import</span
                  ><span class="ladda-spinner"></span>
                </button>
                <a
                  href="/#/auto-reconcile/received/aggregate"
                  class="btn btn-default"
                >
                  <span>Hủy bỏ</span>
                </a>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  name: "Reconcile",
  mixins: [Common],
  data() {
    return {
      file: null,
      type: 1,
      extensions: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      partner: "VCB",
      map_template: {
        VCB: "Cyber_VCB_9PAY_DDMMYYYY_TC.xlsx",
        ONEPAY: "template_OP_9PAY_YYYYMMDD.xlsx",
        VPB: "MCV_VPB_CYBS_YYYYMMDD.xlsx",
        VPB_CYBS: "MCV_VPB_YYYYMMDD.xlsx",
        STB_CYBS: "MCV_STB_YYYYMMDD.xlsx",
        VNPAY_PORTONE: "template_VNPAY_YYYYMMDD.xlsx",
        ZALOPAY_WALLET: "Cyber_VCB_9PAY_DDMMYYYY_TC.xlsx",
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Import danh sách giao dịch" },
    ]);
  },
  methods: {
    getLinkTemplate() {
      return `${process.env.VUE_APP_API_URL}/reconcile-template/${
        this.map_template[this.partner]
      }`;
    },
    async upload() {
      try {
        if (!this.file) {
          alert("Vui lòng chọn file");
          return false;
        }

        if (this.extensions.indexOf(this.file.type) === -1) {
          alert("File không đúng định dạng, vui lòng kiểm tra lại");
          return false;
        }

        if (this.file.size <= 0) {
          alert("File rỗng, vui lòng kiểm tra lại");
          return false;
        }

        let param = new FormData();
        param.append("file", this.file);
        param.append("partner", this.partner);

        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.uploadSuccessFile(param);
        this.$bus.$emit("show-loading", false);
        this.item = response.data.data;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
};
</script>
